import React from 'react';
import { ErrorBoundary } from '@appsignal/react';
import Appsignal from '@appsignal/javascript';

let appsignalInstance : null|Appsignal = null;

if (devex?.data?.appsignalFrontendApiKey) {
  appsignalInstance = new Appsignal({
    key: devex?.data?.appsignalFrontendApiKey,
    namespace: 'react',
  }); 
}

interface Props {
  children: React.ReactNode
}

const DevexHighOrderComponent = ({ children }: Props) => {
  if (appsignalInstance) {
    return (
      <ErrorBoundary instance={appsignalInstance as Appsignal}>
        {children}
      </ErrorBoundary>
    );
  }
  return <>{children}</>;
};

export default DevexHighOrderComponent;
